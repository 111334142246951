
import { defineComponent, PropType } from 'vue';
import { Placement } from '@popperjs/core';

import AppFlyout from '@/components/hoc/AppFlyout.vue';

export default defineComponent({
  name: 'AppTooltip',

  components: { AppFlyout },

  props: {
    placement: {
      type: String as PropType<Placement>,
      default: 'top'
    },

    offset: {
      type: Number,
      default: 10
    },

    tooltipClasses: {
      type: String,
      default: 'app-tooltip'
    },

    trigger: {
      type: String,
      default: 'hover'
    },

    content: {
      type: [String, Number]
    },

    hideArrow: {
      type: Boolean,
      default: true
    },

    arrowClasses: {
      type: String,
      default: 'border border-solid border-grey-fp-20'
    },

    disabled: {
      type: Boolean,
      default: false
    },

    appendToBody: {
      type: Boolean,
      default: true
    }
  }
});
