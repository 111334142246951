<template>
  <!-- A D D   T A S K   S E C T I O N -->
  <div class="flex justify-between items-center py-35">
    <p class="text-dark-cl-20 text-xl">Tasks <span class="text-hushed-lilac">(optional)</span></p>

    <AppButton
      type="secondary"
      icon="plus"
      size="large"
      icon-size="13"
      icon-classes="h-30 w-30"
      custom-class="h-30"
      @click="openModal"
    >
      <span class="text-md ml-6">
        Add Task
      </span>
    </AppButton>
  </div>

  <div class="tasks-wrap overflow-y-auto">
    <Task
      v-for="(task, index) of tasks"
      :key="index"
      :task="task"
      class="my-8"
      @edit="handleEdit(task, index)"
      @remove="handleRemove(index)"
    />
  </div>


  <!-- C R E A T E   T A S K   M O D A L -->
  <portal
    v-if="openCreateTaskModal"
    to="admin"
  >
    <AppModal @cancel="closeModal">
      <div class="w-full min-w-280 max-w-400">
        <p class="text-dark-cl-20 text-md mb-8">{{ taskFormMode === 'create' ? 'Add': 'Edit' }} task</p>
        <!-- F O R M -->
        <TaskForm
          :task="taskForm"
          :mode="taskFormMode"
          @submit="onSubmit"
          @cancel="closeModal"
        />
      </div>
    </AppModal>
  </portal>
</template>

<script lang="ts">
  import { defineComponent, PropType, toRefs, ref, reactive } from 'vue';

  import AppButton from '@/components/stateless/AppButton.vue';
  import AppModal from '@/components/stateless/AppModal.vue';
  import TaskForm from '@/views/admin/goals/components/TaskForm.vue';
  import Task from '@/views/admin/goals/components/Task.vue';

  import { IPredefinedGoalTaskForm } from '@/types';

  export default defineComponent({
    name: 'Taskslist',

    components: { AppButton, AppModal, TaskForm, Task },

    props: {
      tasks: {
        type: Array as PropType<Array<IPredefinedGoalTaskForm>>,
        required: true
      },

      mode: {
        type: String,
        validator: (value: string) => ['create', 'edit'].indexOf(value) !== -1,
        default: 'create'
      }
    },

    emits: ['update:tasks', 'remove', 'update'],

    setup(props, { emit }) {
      const state = reactive({
        taskForm: {
          title: '',
          description: ''
        } as IPredefinedGoalTaskForm
      });
      const taskFormMode = ref<'create' | 'edit'>('create');
      const { tasks } = toRefs(props);
      const { taskForm } = toRefs(state);
      const editedTaskIndex = ref<number>(0);
      const openCreateTaskModal = ref<boolean>(false);

      function closeModal() {
        openCreateTaskModal.value = false;
        taskFormMode.value = 'create';
        taskForm.value = {} as IPredefinedGoalTaskForm;
      }

      function openModal() {
        openCreateTaskModal.value = true;
      }

      function handleEdit(task: IPredefinedGoalTaskForm, index: number) {
        taskForm.value = { ...task };
        taskFormMode.value = 'edit';
        editedTaskIndex.value = index;
        openModal();
      }

      function handleRemove(index: number) {
        emit('update:tasks', tasks.value.filter((task: IPredefinedGoalTaskForm, i: number) => i !== index));
      }

      function onSubmit(payload: IPredefinedGoalTaskForm) {
        if (taskFormMode.value === 'create') {
          emit('update:tasks', [payload, ...tasks.value]);
        } else {
          emit(
            'update:tasks',
            tasks.value.map((task: IPredefinedGoalTaskForm, i: number) => {
              if (i === editedTaskIndex.value) {
                task = payload;
              }
              return task;
            })
          );
        }
        closeModal();
      }

      return {
        openCreateTaskModal,
        taskForm,
        taskFormMode,

        closeModal,
        openModal,
        onSubmit,
        handleEdit,
        handleRemove
      };
    }

  });
</script>

<style lang="scss" scoped>
.tasks-wrap {
  max-height: 250px;
}
</style>