
import { defineComponent } from 'vue';

import AppIcon from '@/components/stateless/AppIcon.vue';

export default defineComponent({
  name: 'AppModal',

  components: { AppIcon },

  props: {
    clickOutside: {
      type: Boolean,
      default: false
    },
    
    closeIcon: {
      type: Boolean,
      default: true
    }
  },

  emits: ['cancel'],

  setup(props, { emit }) {

    function close () {
      if (props.clickOutside) {
        emit('cancel');
      }
    }

    return { close };
  }
});
