<template>
  <AppTooltip
    :disabled="!showTooltip"
    :content="content"
    v-bind="$attrs"
    @mouseenter="checkVisibility"
  >
    <div ref="activator" :class="['truncate', activatorClasses]">{{ content }}</div>
    <template #content>
      <slot name="content">
        {{ content }}
      </slot>
    </template>
  </AppTooltip>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import AppTooltip from '@/components/stateless/AppTooltip.vue';

export default defineComponent({
  name: 'AppTruncatedTooltip',

  components: { AppTooltip },

  props: {
    content: {
      type: [String, Number]
    },
    activatorClasses: {
      type: String,
      default: ''
    }
  },

  setup () {
    const activator = ref();
    const showTooltip = ref(false);

    function checkVisibility () {
      if (activator.value) {
        showTooltip.value = activator.value.scrollWidth > activator.value.clientWidth;
      }
    }

    return {
      activator,
      showTooltip,

      checkVisibility
    };
  }
});
</script>
