
  import { defineComponent, PropType, ref } from 'vue';

  import AppTooltip from '@/components/stateless/AppTooltip.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';
  import AppModal from '@/components/stateless/AppModal.vue';
  import AppButton from '@/components/stateless/AppButton.vue';

  import { IPredefinedGoalTaskForm } from '@/types';

  export default defineComponent({
    name: 'Task',

    components: { AppIcon, AppTooltip, AppTruncatedTooltip, AppModal, AppButton },

    props: {
      task: {
        type: Object as PropType<IPredefinedGoalTaskForm>,
        required: true
      },
    },

    emits: ['remove', 'edit'],

    setup(props, { emit }) {
      const isOpenConfirmationModal = ref<boolean>(false);

      const actions = ref([
        { title: 'Edit', icon: 'pen', handler: edit, customClass: 'text-dark-cl-20' },
        { title: 'Remove', icon: 'trash-alt', handler: openConfirmationModal, customClass: 'text-error' },
      ]);

      function edit() {
        emit('edit');
      }

      function remove() {
        emit('remove');
        hideConfirmationModal();
      }

      function hideConfirmationModal() {
        isOpenConfirmationModal.value = false;
      }

      function openConfirmationModal() {
        isOpenConfirmationModal.value = true;
      }

      return {
        actions,
        isOpenConfirmationModal,

        hideConfirmationModal,
        remove,
      };
    }

  });
