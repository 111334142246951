<template>
  <div class="app-modal fixed top-0 left-0 right-0 bottom-0 z-50">
    <!-- O V E R L A Y -->
    <div class="absolute w-full h-full opacity-60 z-1 bg-grey-fp-70" @click="close" />

    <!-- C O N T E N T -->
    <div 
      class="app-modal__content"
    >
      <AppIcon 
        v-if="closeIcon" 
        name="close"
        size="14"
        class="absolute top-12 right-12 text-grey-fp-60 cursor-pointer" 
        @click="$emit('cancel')"
      />
      <slot />
    </div> 
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import AppIcon from '@/components/stateless/AppIcon.vue';

export default defineComponent({
  name: 'AppModal',

  components: { AppIcon },

  props: {
    clickOutside: {
      type: Boolean,
      default: false
    },
    
    closeIcon: {
      type: Boolean,
      default: true
    }
  },

  emits: ['cancel'],

  setup(props, { emit }) {

    function close () {
      if (props.clickOutside) {
        emit('cancel');
      }
    }

    return { close };
  }
});
</script>

<style lang="scss" scoped>
.app-modal {
  &__content {
    @apply absolute bg-white top-1/2 left-1/2 z-10 
    transform -translate-y-1/2 -translate-x-1/2 p-20 rounded-5;
  }
}
</style>
