
  import { defineComponent, PropType, toRefs, ref, reactive } from 'vue';

  import AppButton from '@/components/stateless/AppButton.vue';
  import AppModal from '@/components/stateless/AppModal.vue';
  import TaskForm from '@/views/admin/goals/components/TaskForm.vue';
  import Task from '@/views/admin/goals/components/Task.vue';

  import { IPredefinedGoalTaskForm } from '@/types';

  export default defineComponent({
    name: 'Taskslist',

    components: { AppButton, AppModal, TaskForm, Task },

    props: {
      tasks: {
        type: Array as PropType<Array<IPredefinedGoalTaskForm>>,
        required: true
      },

      mode: {
        type: String,
        validator: (value: string) => ['create', 'edit'].indexOf(value) !== -1,
        default: 'create'
      }
    },

    emits: ['update:tasks', 'remove', 'update'],

    setup(props, { emit }) {
      const state = reactive({
        taskForm: {
          title: '',
          description: ''
        } as IPredefinedGoalTaskForm
      });
      const taskFormMode = ref<'create' | 'edit'>('create');
      const { tasks } = toRefs(props);
      const { taskForm } = toRefs(state);
      const editedTaskIndex = ref<number>(0);
      const openCreateTaskModal = ref<boolean>(false);

      function closeModal() {
        openCreateTaskModal.value = false;
        taskFormMode.value = 'create';
        taskForm.value = {} as IPredefinedGoalTaskForm;
      }

      function openModal() {
        openCreateTaskModal.value = true;
      }

      function handleEdit(task: IPredefinedGoalTaskForm, index: number) {
        taskForm.value = { ...task };
        taskFormMode.value = 'edit';
        editedTaskIndex.value = index;
        openModal();
      }

      function handleRemove(index: number) {
        emit('update:tasks', tasks.value.filter((task: IPredefinedGoalTaskForm, i: number) => i !== index));
      }

      function onSubmit(payload: IPredefinedGoalTaskForm) {
        if (taskFormMode.value === 'create') {
          emit('update:tasks', [payload, ...tasks.value]);
        } else {
          emit(
            'update:tasks',
            tasks.value.map((task: IPredefinedGoalTaskForm, i: number) => {
              if (i === editedTaskIndex.value) {
                task = payload;
              }
              return task;
            })
          );
        }
        closeModal();
      }

      return {
        openCreateTaskModal,
        taskForm,
        taskFormMode,

        closeModal,
        openModal,
        onSubmit,
        handleEdit,
        handleRemove
      };
    }

  });
