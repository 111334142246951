

import { defineComponent, ref, computed, reactive, PropType, toRefs } from 'vue';

import AppIcon from '@/components/stateless/AppIcon.vue';

import { TIndexedObject, IAppInputError } from '@/types';

type TSizes = 'large' | 'medium' | 'small' | 'mini';

export default defineComponent({
  name: 'AppSelect',

  components: { AppIcon },

  props: {
    modelValue: {
      type: [Object, Array, String] as PropType<TIndexedObject | Array<string | number>>,
      required: true
    },

    label: {
      type: String,
      default: ''
    },

    customClass: {
      type: String,
      default: ''
    },

    error: {
      type: [String, Object as () => IAppInputError],
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },
    
    multiple: {
      type: Boolean,
      default: false
    },

    size: {
      type: String as PropType<TSizes>,
      default: 'medium'
    },

    options: {
      type: Array as PropType<TIndexedObject[]>,
      required: true
    },

    optionKey: {
      type: String,
      default: 'label'
    },

    placeholder: {
      type: String,
      default: ''
    },

    keyValue: {
      type: String,
      default: 'value'
    },

    hideErrorMessage: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:modelValue', 'focus', 'change', 'blur' ],

  setup(props, { emit }) {
    const { modelValue, options, disabled, optionKey, keyValue } = toRefs(props);

    let isOpen = ref<boolean>(false);

    let inputValue = computed<string>(() => {
      if (Array.isArray(modelValue.value)) {
        //TODO multiple
        return '';
      } else {
          const selectedOption = options.value.find((el: TIndexedObject) => {
            if (typeof modelValue.value === 'string') {
              return el[keyValue.value] === modelValue.value;
            } else if ((modelValue.value as TIndexedObject)[keyValue.value]) {
              return el[keyValue.value] === (modelValue.value as TIndexedObject)[keyValue.value];
            }
      
            return false;
        });

        return selectedOption ? selectedOption[optionKey.value] : '';
      }
    });

    const err = computed<IAppInputError>(() => { 
      if (typeof props.error === 'string') {

        return reactive<IAppInputError>({ message: props.error, link: { name: '', message: '' } });
      }

      return props.error;
    });

    function selectItem (option: TIndexedObject) {
      if (disabled.value) { return; }

      emit('update:modelValue', option);
      isOpen.value = false;
      emit('change');
    }

    function onFocus (e: InputEvent) {
      if (disabled.value) { return; }

      isOpen.value = true;
      emit('focus', e);
    }

    function onBlur (e: InputEvent) {
      if (disabled.value) { return; }

      isOpen.value = false;
      emit('blur', e);
    }

    return {
      isOpen,

      inputValue,
      err,

      onFocus,
      selectItem,
      onBlur,
    };
  }
});
