<template>
  <div>
    <!-- L A B E L -->
    <div v-if="$slots.label || label" class="flex items-center justify-between">
      <label class="cursor-pointer mb-10 text-sm sm:text-base text-dark-cl-20">
        <span v-if="!$slots.label">{{ label }}</span>
        <slot name="label" />
      </label>
    </div>

    <div 
      class="textarea__wrap w-full" 
      :class="[{ 'is-disabled' : disabled }, { 'is-readonly': readonly}, { 'is-error': error}]"
    >
      <textarea
        :value="modelValue"
        :placeholder="placeholder"
        :readonly="readonly"
        :disabled="disabled"
        :rows="rows"
        :maxlength="maxlength"
        :class="{ 'resize-none' : !resize }"
        data-test="textarea"
        class="textarea"
        @input="onTextareaHandler"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
      />

      <!-- E R R O R -->
      <p
        v-if="error"
        class="text-red-fp-30 absolute text-sm sm:text-base top-full left-0 mt-2 flex items-center"
      >
        {{ error }}
      </p>

      <p v-if="maxlength" class="text-grey-fp-40 absolute text-sm sm:text-base top-full right-0 mt-2 flex items-center">
        {{ modelValue.length + '/' + maxlength }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppTextarea',

  props: {
    modelValue: {
      type: String,
      default: ''
    },

    label: {
      type: String,
      defaut: ''
    },

    placeholder: {
      type: String,
      default: ''
    },

    resize: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    rows: {
      type: Number,
      default: 3
    },

    maxlength: {
      type: [Number, String, Object],
      default: null
    },

    error: {
      type: String,
      default: ''
    },
  },

  emits: ['update:modelValue', 'focus', 'blur'],

  setup (props, { emit }) {

    function onTextareaHandler (e: any) {
      const target = e.target as HTMLTextAreaElement;

      emit('update:modelValue', target.value);
    }

    return { onTextareaHandler };
  }
});
</script>

<style lang="scss" scoped>
.textarea {
  &__wrap {
    @apply
      relative
      inline-flex
      border border-grey-fp-50
      rounded-5
      bg-white
      text-base
      focus:border-primary
      focus:outline-none transition duration-300;

    &.is-error {
      @apply border-error bg-opacity-20;
    }

    &.is-readonly {
      @apply bg-opacity-30;
    }

    &.is-disabled {
      @apply opacity-50 select-none cursor-not-allowed;
    }

  }
}

.textarea {
  @apply
    w-full h-full
    py-8 px-10
    bg-transparent
    text-dark-cl-20
    text-base
    placeholder-grey-fp-40
    focus:outline-none;

  min-height: 38px;

  &:disabled {
    @apply pointer-events-none;
  }

  &__error-message {
    bottom: -20px;
    left: -2px;
  }
}
</style>
