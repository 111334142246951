import { required, helpers } from "@vuelidate/validators";

export const predefinedGoalsFormRules = {
  title: {
    required: helpers.withMessage('This field cannot be empty', required),
  },
  category: {
    required: helpers.withMessage('This field cannot be empty', required),
  },
  tasks: {}
};

export const taskFormRules = {
  title: {
    required: helpers.withMessage('This field cannot be empty', required),
  },
  description: {}
};
