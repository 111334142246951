
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppTextarea',

  props: {
    modelValue: {
      type: String,
      default: ''
    },

    label: {
      type: String,
      defaut: ''
    },

    placeholder: {
      type: String,
      default: ''
    },

    resize: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    rows: {
      type: Number,
      default: 3
    },

    maxlength: {
      type: [Number, String, Object],
      default: null
    },

    error: {
      type: String,
      default: ''
    },
  },

  emits: ['update:modelValue', 'focus', 'blur'],

  setup (props, { emit }) {

    function onTextareaHandler (e: any) {
      const target = e.target as HTMLTextAreaElement;

      emit('update:modelValue', target.value);
    }

    return { onTextareaHandler };
  }
});
