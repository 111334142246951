<template>
  <div>
    <!-- F O R M -->
    <div class="py-10">
      <form @submit.prevent="onSubmit">
        <div class="max-w-350">
          <AppInput
            v-model="v$.title.$model"
            name="title"
            class="mb-20"
            maxlength="100"
            :error="errorMessages.title"
            @focus="clearErrorField('title')"
          />

          <AppTextarea
            v-model="v$.description.$model"
            placeholder="Description"
            :error="errorMessages.description"
            maxlength="1000"
            class="w-full"
            @focus="clearErrorField('description')"
          />
        </div>
      </form>
    </div>

    <!-- A C T I O N S -->
    <div class="flex items-center justify-end mt-30">
      <AppButton
        type="primary"
        size="mini"
        class="px-20 mr-10"
        @click="onSubmit"
      >
        {{ mode === 'create' ? 'Save' : 'Save changes' }}
      </AppButton>
      <AppButton
        size="mini"
        plain
        class="px-20"
        @click="onCancel"
      >
        Cancel
      </AppButton>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, PropType, toRefs } from 'vue';
  import { useVuelidate } from "@vuelidate/core";

  import AppInput from '@/components/stateless/AppInput.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppTextarea from '@/components/stateless/AppTextarea.vue';

  import { IErrorFields, IPredefinedGoalTaskForm } from '@/types';
  import { clearErrorField, handleSetErrors, validateField } from '@/core/helper-functions';
  import { taskFormRules } from '@/views/admin/goals/validation-rules';
  import { routesNames, router } from '@/router';

  export default defineComponent({
    name: 'TaskForm',

    components: {  AppButton, AppTextarea, AppInput },

    props: {
      task: {
        type: Object as PropType<IPredefinedGoalTaskForm>,
        required: true
      },

      mode: {
        type: String,
        validator: (value: string) => ['create', 'edit'].indexOf(value) !== -1,
        default: 'create'
      },

      disabledAction: {
        type: Boolean,
        default: false
      }
    },

    emits: ['submit', 'cancel'],

    setup(props, { emit }) {
      const { task } = toRefs(props);

      const errorMessages = reactive<IErrorFields>({
        title: '',
        description: ''
      });

      const v$ = useVuelidate(taskFormRules, task.value, { $stopPropagation: true});

      function onCancel () {
        emit('cancel');
      }

      async function onSubmit() {

        if (await v$.value.$validate()) { 
          emit('submit', task.value); 
        }
        else { handleSetErrors(v$.value.$errors, errorMessages); }
      }

      return {
        errorMessages,
        router,
        routesNames,

        v$,

        onSubmit,
        onCancel,
        validateField: (name: string) => validateField(name, v$, errorMessages),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages),
      };
    }

  });
</script>