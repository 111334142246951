<template>
  <!-- U N S A V E D  C H A N G E S  H A N D L E R -->
  <portal
    v-if="showRouterHandlerModal"
    :to="vuex.isAdmin ? 'admin' : 'default'"
  >
    <AppModal @cancel="cancelNavigationAction">
      <div class="w-full min-w-280 max-w-360">
        <p class="text-dark-cl-20 text-md mb-8">You have unsaved changes</p>
        <p class="text-grey-fp-60 mb-40">You have unsaved changes. Are you sure you want to leave?</p>
        <div class="flex items-center justify-end">
          <AppButton
            size="mini"
            type="primary"
            class="px-20 mr-10"
            @click="cancelNavigationAction"
          >
            Stay
          </AppButton>
          <AppButton
            size="mini"
            plain
            class="px-20"
            @click="confirmNavigationAction"
          >
            Leave without saving
          </AppButton>
        </div>
      </div>
    </AppModal>
  </portal>
</template>

<script lang="ts">
  import { defineComponent, PropType, toRaw, ref, toRefs, onBeforeUnmount, onBeforeMount } from 'vue';
  import { onBeforeRouteLeave } from 'vue-router';
  import { isEqual } from 'lodash';

  import AppModal from '@/components/stateless/AppModal.vue';
  import AppButton from '@/components/stateless/AppButton.vue';

  import { TIndexedObject } from '@/types';
  import { vuex } from '@/store';

  export default defineComponent({
    name: 'UnsavedChangesModal',

    components: { AppButton, AppModal },

    props: {
      data: {
        type: Object as PropType<TIndexedObject>,
        required: true
      },

      disabled: {
        type: Boolean,
        default: false
      },
    },

    setup(props) {
      const { data, disabled } = toRefs(props);
      let resolveNavigationGuard: any = null;
      let rejectNavigationGuard: any = null;
      const showRouterHandlerModal = ref<boolean>(false);

      const rawData = toRaw(data.value);
      const copy = JSON.parse(JSON.stringify(rawData));

      function confirmNavigationAction() {
        showRouterHandlerModal.value = false;
        resolveNavigationGuard();
      }

      function cancelNavigationAction() {
        rejectNavigationGuard();
        showRouterHandlerModal.value = false;
      }

      function handlePageRefresh(event: Event) {

            if (isEqual(rawData, copy)) return;

            // Prevent refresh page behavior
            event.preventDefault();
            event.returnValue = false;
        }

      function handleNavigationGuard() {

        return new Promise((resolve, reject) => {
          showRouterHandlerModal.value = true;
          resolveNavigationGuard = resolve;
          rejectNavigationGuard = reject;
        });
      }

      onBeforeRouteLeave((to, from, next) => {

        if (!isEqual(rawData, copy) && !disabled.value) {
          handleNavigationGuard()
            .then(() => next())
            .catch(() => next(false));
        } else {
          next();
        }
      });

      onBeforeUnmount(() => {
        window.removeEventListener('beforeunload', handlePageRefresh);
      });

      onBeforeMount(() => {
        window.addEventListener('beforeunload', handlePageRefresh);
      });

      return {
        showRouterHandlerModal,

        vuex,

        confirmNavigationAction,
        cancelNavigationAction,
      };
    }

  });
</script>