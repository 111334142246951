<template>
  <AppFlyout
    :disabled="disabled"
    :placement="placement"
    :offset="offset"
    :trigger="trigger"
    :popperClasses="tooltipClasses"
    :showArrow="!hideArrow"
    :arrowClasses="arrowClasses"
    :appendToBody="appendToBody"
  >
    <template #activator>
      <slot />
    </template>
    <template #popper="{ handleHidePopper }">
      <slot name="content" :handleHidePopper="handleHidePopper">
        {{ content }}
      </slot>
    </template>
  </AppFlyout>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Placement } from '@popperjs/core';

import AppFlyout from '@/components/hoc/AppFlyout.vue';

export default defineComponent({
  name: 'AppTooltip',

  components: { AppFlyout },

  props: {
    placement: {
      type: String as PropType<Placement>,
      default: 'top'
    },

    offset: {
      type: Number,
      default: 10
    },

    tooltipClasses: {
      type: String,
      default: 'app-tooltip'
    },

    trigger: {
      type: String,
      default: 'hover'
    },

    content: {
      type: [String, Number]
    },

    hideArrow: {
      type: Boolean,
      default: true
    },

    arrowClasses: {
      type: String,
      default: 'border border-solid border-grey-fp-20'
    },

    disabled: {
      type: Boolean,
      default: false
    },

    appendToBody: {
      type: Boolean,
      default: true
    }
  }
});
</script>

<style lang="scss">
.app-tooltip {
  @apply text-white bg-grey-fp-70 p-10 rounded-5 font-medium shadow
    border border-solid border-grey-fp-20 overflow-auto;
  max-width: 880px;
  max-height: 200px;
}
</style>
