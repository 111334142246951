
  import { defineComponent, onMounted, onBeforeUnmount, nextTick, ref, Ref, PropType, watch } from 'vue';
  import { createPopper, Placement } from '@popperjs/core';

  import AppButton from '@/components/stateless/AppButton.vue';

  import { onClickOutside } from '@/hooks';

  export default defineComponent({
    name: 'AppFlyout',

    components: { AppButton },

    props: {
      popperClasses: {
        type: String,
        default: 'py-5 bg-white text-dark-cl-20'
      },

      arrowClasses: {
        type: String,
        default: ''
      },

      btnTitle: {
        type: String,
        default: 'Button'
      },

      disabled: {
        type: Boolean,
        default: false
      },

      appendToBody: {
        type: Boolean,
        default: false
      },

      showArrow: {
        type: Boolean,
        default: false
      },

      trigger: {
        type: String as PropType<'click' | 'hover'>,
        default: 'click'
      },

      placement: {
        type: String as PropType<Placement>,
        default: 'bottom'
      },

      offset: {
        type: Number,
        default: 5
      },

      openDelay: {
        type: Number,
        default: 100
      },

      closeDelay: {
        type: Number,
        default: 100
      }
    },

    setup(props) {
      const instancePopover = ref();
      const button = ref() as Ref<HTMLElement>;
      const popper = ref() as Ref<HTMLElement>;
      const isVisible = ref(false);
      const popoverOptions = {
        placement: props.placement,
        modifiers: [
          {
            name: 'offset',
            options: { offset: [0, props.offset] }
          },
          {
            name: 'arrow',
            options: { padding: 10 }
          }
        ]
      };
      const hiddenClass = 'hidden';
      let timeoutShowPopper: ReturnType<typeof setTimeout>;
      let timeoutHidePopper: ReturnType<typeof setTimeout>;

      function hidePopover() {
        popper.value.classList.add(hiddenClass);
        isVisible.value = false;
      }

      function handleHidePopper(event: null | Event, shouldDelay = true) {
        clearTimeout(timeoutShowPopper);
        timeoutHidePopper = setTimeout(() => {
          if (popper.value) hidePopover();
        }, shouldDelay && props.trigger === 'hover' ? props.closeDelay : 0);
      }

      function showPopover() {
        popper.value.classList.remove(hiddenClass);
        isVisible.value = true;
      }

      function handleShowPopper() {
        clearTimeout(timeoutHidePopper);
        timeoutShowPopper = setTimeout(() => {
          nextTick(() => {
            if (popper.value && button.value) {
              if (!popper.value.hasAttribute('data-popper-placement')) {
                // Create popover via popper.js
                instancePopover.value = createPopper(button.value, popper.value, popoverOptions);

                // Apply popover to the end of the body
                if (props.appendToBody) document.body.appendChild(popper.value);

                if (props.trigger === 'hover') {
                  popper.value.addEventListener('mouseenter', () => {
                    clearTimeout(timeoutHidePopper);
                  });
                  popper.value.addEventListener('mouseleave', handleHidePopper);
                }
              } else instancePopover.value.update();

              props.trigger === 'click' && isVisible.value ? hidePopover() : showPopover();
            }
          });
        }, props.trigger === 'hover' ? props.openDelay : 0);
      }

      function createEventListener() {
        if (props.trigger === 'click') button.value.addEventListener('click', handleShowPopper);
        if (props.trigger === 'hover') {
          button.value.addEventListener('mouseenter', handleShowPopper);
          button.value.addEventListener('mouseleave', handleHidePopper);
        }
      }

      function removeEventListener() {
        if (props.trigger === 'click') button.value.removeEventListener('click', handleShowPopper);
        if (props.trigger === 'hover') {
          button.value.removeEventListener('mouseenter', handleShowPopper);
          button.value.removeEventListener('mouseleave', handleHidePopper);
        }
      }

      watch(() => props.disabled, (newValue: boolean) => { newValue ? removeEventListener() : createEventListener(); });

      onMounted(() => { if (!props.disabled) createEventListener(); });

      onBeforeUnmount(() => { removeEventListener(); });

      onClickOutside(() => { handleHidePopper(null, false); });

      return {
        button,
        popper,
        hiddenClass,
        isVisible,

        handleHidePopper
      };
    }
  });
