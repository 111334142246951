import { IRequestParams } from '@/types';

export const GOALS_PAGINATION_LIMIT = 10;

export const predefinedGoalsParams = (offset: number, order: Array<string[]>, status: string): IRequestParams => ({
  params: {
    filter: {
      limit: GOALS_PAGINATION_LIMIT,
      offset,
      order: order.length ? order : [ ["createdAt", "DESC"] ],
      where: status === 'custom' 
      ? {
        status: {
          "$in": ["opened", "achieved"]
        },
        parentId: null
        } 
      : {
          status
        },
      include: [
        {
          association: "category"
        },
        {
          association: "tasks",
          identifier: "tasks",
          count: true
        },
        {
          association: "derivedGoals",
          identifier: "completedDerivedGoals",
          count: true,
          where: {
            status: "achieved"
          }
        },
        {
          association: "derivedGoals",
          identifier: "inProgressDerivedGoals",
          count: true,
          where: {
            status: "opened"
          }
        }
      ]
    },
    includeCount: true 
  }
});

export const PREDEFINED_GOAL_PARAMS: IRequestParams = {
  params: {
    filter: {
      include: [
        { model: 'task' },
        { model: "category" }
      ],
      where: {
        status: "predefined"
      }
    }
  }
};

export const CATEGORIES: IRequestParams = {
  params: {
    filter: {
      where: {
        status: 'active'
      },
    }
  }
};