
  import { defineComponent, reactive, PropType, toRefs, computed, toRaw } from 'vue';
  import { useVuelidate } from "@vuelidate/core";
  import { isEqual } from 'lodash';

  import AppInput from '@/components/stateless/AppInput.vue';
  import AppSelect from '@/components/stateless/AppSelect.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import TasksList from '@/views/admin/goals/components/TasksList.vue';

  import { IErrorFields, IPredefinedGoalForm, ICategory, IPredefinedGoalTaskForm } from '@/types';
  import { clearErrorField, handleSetErrors, validateField, getChangedData } from '@/core/helper-functions';
  import { predefinedGoalsFormRules } from '@/views/admin/goals/validation-rules';
  import { routesNames, router } from '@/router';

  export default defineComponent({
    name: 'GoalForm',

    components: { AppInput, AppSelect, AppButton, AppIcon, TasksList },

    props: {
      goal: {
        type: Object as PropType<IPredefinedGoalForm>,
        required: true
      },

      originalGoalData: {
        type: Object as PropType<IPredefinedGoalForm>,
        default: null
      },

      categoriesList: {
        type: Array as PropType<ICategory[]>,
        default: () => []
      },

      mode: {
        type: String,
        validator: (value: string) => ['create', 'edit'].indexOf(value) !== -1,
        default: 'create'
      }
    },

    emits: ['submit', 'archive', 'update'],

    setup(props, { emit }) {
      const { goal } = toRefs(props);
      const { tasks } = toRefs(goal.value);

      const errorMessages = reactive<IErrorFields>({
        title: '',
        category: ''
      });

      const v$ = useVuelidate(predefinedGoalsFormRules, goal.value);

      const disabledAction = computed<boolean>(() => {
        return isEqual({ ...goal.value, tasks: tasks.value }, props.originalGoalData);
      });

      function mapTasks(tasks: IPredefinedGoalTaskForm[]) {
        return tasks.map((task: IPredefinedGoalTaskForm) => {
          return { ...task, description: task.description || null };
        });
      }

      async function onSubmit() {
        if (await v$.value.$validate()) {
          if (props.mode === 'create') {
            emit('submit', {
              title: goal.value.title,
              categoryId: goal.value.category.id,
              tasks: mapTasks(tasks.value as IPredefinedGoalTaskForm[])
            });
          } else {

            emit('submit', {
              ...getChangedData({ title: props.originalGoalData.title }, { title: goal.value.title }),
              tasks: mapTasks(toRaw(tasks.value as IPredefinedGoalTaskForm[]))
            });
          }
        }
        else { handleSetErrors(v$.value.$errors, errorMessages); }
      }

      return {
        errorMessages,
        router,
        routesNames,
        tasks,

        v$,
        disabledAction,

        onSubmit,
        validateField: (name: string) => validateField(name, v$, errorMessages),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages),
      };
    }

  });
