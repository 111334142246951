<template>
  <div class="app-select flex flex-col relative">
    <!-- L A B E L -->
    <div v-if="$slots.label || label" class="flex items-center justify-between">
      <label class="cursor-pointer mb-8 text-sm sm:text-base text-dark-cl-20">
        <span v-if="!$slots.label">{{ label }}</span>
        <slot name="label" />
      </label>
    </div>

    <!-- D R O P D O W N -->
    <v-dropdown
      :triggers="[]"
      :shown="isOpen"
      :autoHide="false"
      container=".app-select"
      :offset="[0, 10]"
      placement="bottom"
    >
      <!-- T R I G G E R  -->
      <div 
        class="relative bg-white flex items-center justify-between text-grey-fp-50" 
        data-test="trigger-wrap"
      >
        <input
          type="text"
          :value="inputValue"
          :class="{ disabled, 'error': err.message }"
          :placeholder="placeholder"
          readonly
          class="select text-dark-cl-20 border-grey-fp-50 bg-transparent z-5 truncate"
          data-test="select-input"
          @focus="onFocus"
          @blur="onBlur"
        >
        <AppIcon 
          name="arrow-close"
          size="24"
          class="app-select__icon transform" 
          :class="isOpen ? 'rotate-180 text-primary' : 'rotate-0'"
        />
      </div>

      <!-- P O P P E R -->
      <template #popper>
        <ul 
          v-if="options.length" 
          class="overflow-y-auto py-7 max-h-200 custom-scrollbar rounded-5 shadow-20" 
          data-test="select-options"
        >
          <li
            v-for="option in options"
            :key="option[keyValue]"
            data-test="option"
            class="flex items-center text-dark-cl-20 py-8 px-15 cursor-pointer hover:bg-blue-fp-40"
            :class="{ 'bg-blue-fp-40': option[keyValue] === inputValue}"
            @click="selectItem(option)"
          >
            <div class="flex items-center w-full h-full">
              <span class="truncate">{{ option[optionKey] }}</span>
            </div>
          </li>
        </ul>
        <p v-else class="rounded-5 shadow-20 py-8 px-15 text-sm text-center text-grey-fp-50 italic">
          No items
        </p>
      </template>
    </v-dropdown>

    <!-- E R R O R -->
    <p
      v-if="error && !hideErrorMessage"
      class="text-red-fp-30 absolute text-sm sm:text-base top-full left-0 mt-2 flex items-center"
    >
      <span class="mr-3">{{ error }}</span>
    </p>
  </div>
</template>

<script lang="ts">

import { defineComponent, ref, computed, reactive, PropType, toRefs } from 'vue';

import AppIcon from '@/components/stateless/AppIcon.vue';

import { TIndexedObject, IAppInputError } from '@/types';

type TSizes = 'large' | 'medium' | 'small' | 'mini';

export default defineComponent({
  name: 'AppSelect',

  components: { AppIcon },

  props: {
    modelValue: {
      type: [Object, Array, String] as PropType<TIndexedObject | Array<string | number>>,
      required: true
    },

    label: {
      type: String,
      default: ''
    },

    customClass: {
      type: String,
      default: ''
    },

    error: {
      type: [String, Object as () => IAppInputError],
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },
    
    multiple: {
      type: Boolean,
      default: false
    },

    size: {
      type: String as PropType<TSizes>,
      default: 'medium'
    },

    options: {
      type: Array as PropType<TIndexedObject[]>,
      required: true
    },

    optionKey: {
      type: String,
      default: 'label'
    },

    placeholder: {
      type: String,
      default: ''
    },

    keyValue: {
      type: String,
      default: 'value'
    },

    hideErrorMessage: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:modelValue', 'focus', 'change', 'blur' ],

  setup(props, { emit }) {
    const { modelValue, options, disabled, optionKey, keyValue } = toRefs(props);

    let isOpen = ref<boolean>(false);

    let inputValue = computed<string>(() => {
      if (Array.isArray(modelValue.value)) {
        //TODO multiple
        return '';
      } else {
          const selectedOption = options.value.find((el: TIndexedObject) => {
            if (typeof modelValue.value === 'string') {
              return el[keyValue.value] === modelValue.value;
            } else if ((modelValue.value as TIndexedObject)[keyValue.value]) {
              return el[keyValue.value] === (modelValue.value as TIndexedObject)[keyValue.value];
            }
      
            return false;
        });

        return selectedOption ? selectedOption[optionKey.value] : '';
      }
    });

    const err = computed<IAppInputError>(() => { 
      if (typeof props.error === 'string') {

        return reactive<IAppInputError>({ message: props.error, link: { name: '', message: '' } });
      }

      return props.error;
    });

    function selectItem (option: TIndexedObject) {
      if (disabled.value) { return; }

      emit('update:modelValue', option);
      isOpen.value = false;
      emit('change');
    }

    function onFocus (e: InputEvent) {
      if (disabled.value) { return; }

      isOpen.value = true;
      emit('focus', e);
    }

    function onBlur (e: InputEvent) {
      if (disabled.value) { return; }

      isOpen.value = false;
      emit('blur', e);
    }

    return {
      isOpen,

      inputValue,
      err,

      onFocus,
      selectItem,
      onBlur,
    };
  }
});
</script>

<style lang="scss" scoped>
.app-select {
  &__icon {
    @apply transition duration-300 -translate-y-1/2 top-1/2 absolute flex right-10 z-1;
  }

  .select {
    &:focus:not(.disabled) {
      @apply border-primary;
    }
    &.disabled {
      @apply opacity-50 cursor-not-allowed;
    }
  }
}
</style>

