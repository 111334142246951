
  import { defineComponent, reactive, PropType, toRefs } from 'vue';
  import { useVuelidate } from "@vuelidate/core";

  import AppInput from '@/components/stateless/AppInput.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppTextarea from '@/components/stateless/AppTextarea.vue';

  import { IErrorFields, IPredefinedGoalTaskForm } from '@/types';
  import { clearErrorField, handleSetErrors, validateField } from '@/core/helper-functions';
  import { taskFormRules } from '@/views/admin/goals/validation-rules';
  import { routesNames, router } from '@/router';

  export default defineComponent({
    name: 'TaskForm',

    components: {  AppButton, AppTextarea, AppInput },

    props: {
      task: {
        type: Object as PropType<IPredefinedGoalTaskForm>,
        required: true
      },

      mode: {
        type: String,
        validator: (value: string) => ['create', 'edit'].indexOf(value) !== -1,
        default: 'create'
      },

      disabledAction: {
        type: Boolean,
        default: false
      }
    },

    emits: ['submit', 'cancel'],

    setup(props, { emit }) {
      const { task } = toRefs(props);

      const errorMessages = reactive<IErrorFields>({
        title: '',
        description: ''
      });

      const v$ = useVuelidate(taskFormRules, task.value, { $stopPropagation: true});

      function onCancel () {
        emit('cancel');
      }

      async function onSubmit() {

        if (await v$.value.$validate()) { 
          emit('submit', task.value); 
        }
        else { handleSetErrors(v$.value.$errors, errorMessages); }
      }

      return {
        errorMessages,
        router,
        routesNames,

        v$,

        onSubmit,
        onCancel,
        validateField: (name: string) => validateField(name, v$, errorMessages),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages),
      };
    }

  });
