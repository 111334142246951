
  import { defineComponent, PropType, toRaw, ref, toRefs, onBeforeUnmount, onBeforeMount } from 'vue';
  import { onBeforeRouteLeave } from 'vue-router';
  import { isEqual } from 'lodash';

  import AppModal from '@/components/stateless/AppModal.vue';
  import AppButton from '@/components/stateless/AppButton.vue';

  import { TIndexedObject } from '@/types';
  import { vuex } from '@/store';

  export default defineComponent({
    name: 'UnsavedChangesModal',

    components: { AppButton, AppModal },

    props: {
      data: {
        type: Object as PropType<TIndexedObject>,
        required: true
      },

      disabled: {
        type: Boolean,
        default: false
      },
    },

    setup(props) {
      const { data, disabled } = toRefs(props);
      let resolveNavigationGuard: any = null;
      let rejectNavigationGuard: any = null;
      const showRouterHandlerModal = ref<boolean>(false);

      const rawData = toRaw(data.value);
      const copy = JSON.parse(JSON.stringify(rawData));

      function confirmNavigationAction() {
        showRouterHandlerModal.value = false;
        resolveNavigationGuard();
      }

      function cancelNavigationAction() {
        rejectNavigationGuard();
        showRouterHandlerModal.value = false;
      }

      function handlePageRefresh(event: Event) {

            if (isEqual(rawData, copy)) return;

            // Prevent refresh page behavior
            event.preventDefault();
            event.returnValue = false;
        }

      function handleNavigationGuard() {

        return new Promise((resolve, reject) => {
          showRouterHandlerModal.value = true;
          resolveNavigationGuard = resolve;
          rejectNavigationGuard = reject;
        });
      }

      onBeforeRouteLeave((to, from, next) => {

        if (!isEqual(rawData, copy) && !disabled.value) {
          handleNavigationGuard()
            .then(() => next())
            .catch(() => next(false));
        } else {
          next();
        }
      });

      onBeforeUnmount(() => {
        window.removeEventListener('beforeunload', handlePageRefresh);
      });

      onBeforeMount(() => {
        window.addEventListener('beforeunload', handlePageRefresh);
      });

      return {
        showRouterHandlerModal,

        vuex,

        confirmNavigationAction,
        cancelNavigationAction,
      };
    }

  });
