<template>
  <div class="tasks grid gap-x-10 py-10 px-15 bg-grey-fp-20 rounded">
    <div class="flex items-center">
      <AppTruncatedTooltip
        placement="bottom-start"
        class="text-dark-cl-20"
        :content="task.title"
      />
    </div>
    <div class="flex items-center">
      <AppTruncatedTooltip
        placement="bottom-start"
        class="text-dark-cl-20"
        :content="task.description || '-'"
      />
    </div>
    <div class="flex items-center">
      <AppTooltip
        placement="right-start"
        trigger="click"
        :offset="0"
        tooltip-classes="bg-white rounded-5 font-medium shadow border border-solid border-grey-fp-20"
      >
        <div class="flex item-center cursor-pointer">
          <AppIcon
            name="vertical-dots"
            class="text-grey-fp-50"
            size="20"
          />
        </div>
        <template #content="{ handleHidePopper }">
          <ul class="actions-wrapper py-10">
            <li
              v-for="(action, i) in actions"
              :key="i"
              class="action flex items-center cursor-pointer py-7 px-10"
              :class="action.customClass"
              @click="action.handler(handleHidePopper)"
            >
              <AppIcon
                :name="action.icon"
                size="12"
                class="mr-10"
              />
              <span>{{ action.title }}</span>
            </li>
          </ul>
        </template>
      </AppTooltip>
    </div>

    <!-- R E M O V E  C O N F I R M A T I O N -->
    <portal
      v-if="isOpenConfirmationModal"
      to="admin"
    >
      <AppModal @cancel="hideConfirmationModal">
        <div class="w-full min-w-280 max-w-400">
          <p class="text-dark-cl-20 text-md mb-8">Remove task</p>
          <p class="text-grey-fp-60 mb-40">Are you sure you want to remove this task?</p>
          <div class="flex items-center justify-end">
            <AppButton
              type="primary"
              size="mini"
              class="px-20 mr-10"
              @click="hideConfirmationModal"
            >
              Cancel
            </AppButton>
            <AppButton
              size="mini"
              plain
              class="px-20"
              @click="remove"
            >
              Yes
            </AppButton>
          </div>
        </div>
      </AppModal>
    </portal>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, ref } from 'vue';

  import AppTooltip from '@/components/stateless/AppTooltip.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';
  import AppModal from '@/components/stateless/AppModal.vue';
  import AppButton from '@/components/stateless/AppButton.vue';

  import { IPredefinedGoalTaskForm } from '@/types';

  export default defineComponent({
    name: 'Task',

    components: { AppIcon, AppTooltip, AppTruncatedTooltip, AppModal, AppButton },

    props: {
      task: {
        type: Object as PropType<IPredefinedGoalTaskForm>,
        required: true
      },
    },

    emits: ['remove', 'edit'],

    setup(props, { emit }) {
      const isOpenConfirmationModal = ref<boolean>(false);

      const actions = ref([
        { title: 'Edit', icon: 'pen', handler: edit, customClass: 'text-dark-cl-20' },
        { title: 'Remove', icon: 'trash-alt', handler: openConfirmationModal, customClass: 'text-error' },
      ]);

      function edit() {
        emit('edit');
      }

      function remove() {
        emit('remove');
        hideConfirmationModal();
      }

      function hideConfirmationModal() {
        isOpenConfirmationModal.value = false;
      }

      function openConfirmationModal() {
        isOpenConfirmationModal.value = true;
      }

      return {
        actions,
        isOpenConfirmationModal,

        hideConfirmationModal,
        remove,
      };
    }

  });
</script>


<style scoped lang="scss">
.tasks {
  grid-template-columns: 30% 65% 4%;
}
</style>